import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const partnerList = ['Graphic design', 'Animation', 'Advertising', 'Motion design', 'Brand ID', 'Video editing', 'Print', 'Digital Signage', 'Lyrics video'];

export default function BrandAnimation() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  return (
    <ul>
      <Slider {...settings}>
        {partnerList.map((val, i) => (
          <li className='item' key={i}>
            <h3 className='skillScroll'>{val}</h3>
          </li>
        ))}
        {/* End single parter image */}
      </Slider>
    </ul>
  );
}
