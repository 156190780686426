/* Important:
 Category / Items object can have an extra 'imgIndex'. It is only neccessary if target image is not valid 16/9 aspect ratio 
*/
export const _CONFIG = {
  showNumbersInPortfolio: false
};
export const showReel = { videoSrc: './portfolio/motion/showreel.mp4', imgSrc: './portfolio/motion/rtve_reel2020.jpg' };
export const portfolioList = [
  {
    // This category will be automatically built / updated. Manual copying is unnecessary
    category: 'All',
    items: []
  },
  {
    category: 'Opener',
    items: [
      {
        img: '1',
        title: 'Rally Broadcast Package | Motion Design - Video Editing',
        imgSrc: './portfolio/motion/padlogaz.jpg',
        videoSrc: './portfolio/motion/padlogaz_long.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 100
      },
      {
        img: '2',
        title: 'Sport Broadcast package | Motion Design - Graphic Design',
        imgSrc: './portfolio/motion/lelato.jpg',
        videoSrc: './portfolio/motion/lelato_long.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 200
      },
      {
        img: '3',
        title: 'Urban history show teaser | Logo Design - Animation',
        imgSrc: './portfolio/motion/legendas_terseg.jpg',
        videoSrc: './portfolio/motion/legendas_terseg.mp4',
        meta: 'Branding',
        size: '1920-1080',
        positionInAllCategory: 250
      },

      {
        img: '4',
        title: 'Show opener | Logo Design - Animation',
        imgSrc: './portfolio/motion/az_utca_embere.jpg',
        videoSrc: './portfolio/motion/az_utca_embere.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 1200
      },
      {
        img: '5',
        title: 'Seasonal movie trailers wish  | Logo Design - Animation',
        imgSrc: './portfolio/motion/mozipercek.jpg',
        videoSrc: './portfolio/motion/mozipercek.mp4',
        meta: 'Mozipercek',
        size: '1920-1080',
        positionInAllCategory: 300
      },
      {
        img: '6',
        title: 'Vocational guidance for 8th graders intro | Motion design - Logo Design - Animation',
        imgSrc: './portfolio/motion/mi_a_palya.jpg',
        videoSrc: './portfolio/motion/mi_a_palya.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 1400
      },
      {
        img: '7',
        title: 'Poem series | Logo Design - Animation',
        imgSrc: './portfolio/motion/versek.jpg',
        videoSrc: './portfolio/motion/versek.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 1100
      },
      {
        img: '8',
        title: 'History show opener | Motion Design - Video Editing',
        imgSrc: './portfolio/motion/helytorteneti_magazin.jpg',
        videoSrc: './portfolio/motion/helytorteneti_magazin.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 350
      },
      {
        img: '9',
        title: 'Political talk show opener | Motion Design',
        imgSrc: './portfolio/motion/egyenesben.jpg',
        videoSrc: './portfolio/motion/egyenesben_long.mp4',
        meta: 'Magazine',
        size: '1920-1080',
        positionInAllCategory: 500
      },
      {
        img: '10',
        title: '"How it\'s made" series opener | Motion Design - Video Editing',
        imgSrc: './portfolio/motion/ceger.jpg',
        videoSrc: './portfolio/motion/ceger.mp4',
        meta: 'Graphics',
        size: '1920-1080',
        positionInAllCategory: 1700
      },
      {
        img: '11',
        title: 'Talk Show opener | Motion Design - 3D animation',
        imgSrc: './portfolio/motion/hatter.jpg',
        videoSrc: './portfolio/motion/hatter.mp4',
        meta: 'Háttér',
        size: '1920-1080',
        positionInAllCategory: 3000
      },
      {
        img: '12',
        title: 'Breaking news intro | Motion Design',
        imgSrc: './portfolio/motion/kommentar_nelkul.jpg',
        videoSrc: './portfolio/motion/kommentar_nelkul.mp4',
        meta: 'Branding',
        size: '1920-1080',
        positionInAllCategory: 2300
      }
    ]
  },
  {
    category: 'Ident and Promo',
    items: [
      {
        img: '1',
        title: 'Thematic tv  programme - movies ident | Motion design',
        imgSrc: './portfolio/motion/ajanlo_filmblokk.jpg',
        videoSrc: './portfolio/motion/ajanlo_filmblokk.mp4',
        meta: 'Promo',
        size: '1920-1080',
        positionInAllCategory: 700
      },
      {
        img: '2',
        title: 'Thematic tv  programme - news ident | Motion design',
        imgSrc: './portfolio/motion/ajanlo_hirblokk.jpg',
        videoSrc: './portfolio/motion/ajanlo_hirblokk.mp4',
        meta: 'Promo',
        size: '1920-1080',
        positionInAllCategory: 800
      },
      {
        img: '3',
        title: 'Thematic tv  programme - cultural ident | Motion design',
        imgSrc: './portfolio/motion/ajanlo_kulturblokk.jpg',
        videoSrc: './portfolio/motion/ajanlo_kulturblokk.mp4',
        meta: 'Promo',
        size: '1920-1080',
        positionInAllCategory: 900
      },
      {
        img: '4',
        title: 'Thematic tv  programme - entertainment ident | Motion design',
        imgSrc: './portfolio/motion/ajanlo_magazinblokk.jpg',
        videoSrc: './portfolio/motion/ajanlo_magazinblokk.mp4',
        meta: 'Promo',
        size: '1920-1080',
        positionInAllCategory: 1000
      },
      {
        img: '5',
        title: 'Commercial bumpers | Motion Design',
        imgSrc: './portfolio/motion/elvalaszto_reklam.jpg',
        videoSrc: './portfolio/motion/elvalaszto_reklam.mp4',
        meta: 'Branding',
        size: '1920-1080',
        positionInAllCategory: 2500
      },

      {
        img: '6',
        title: 'Christmas channel ident | Motion Design',
        imgSrc: './portfolio/motion/rtve_karacsony.jpg',
        videoSrc: './portfolio/motion/rtve_karacsony.mp4',
        meta: 'RTVE Karácsony',
        size: '1920-1080',
        positionInAllCategory: 2200
      },

      /*
      {
        img: '8',
        title: 'Tv content rating ident | Motion design - 3D Animation',
        imgSrc: './portfolio/motion/korhatar.jpg',
        videoSrc: './portfolio/motion/korhatar.mp4',
        meta: 'RTVE korhatár',
        size: '1920-1080',
        positionInAllCategory: 2800
      },*/
      {
        img: '9',
        title: 'Channel ident | Motion Design - Video Editing',
        imgSrc: './portfolio/motion/dronos_elvalaszto.jpg',
        videoSrc: './portfolio/motion/dronos_elvalaszto.mp4',
        meta: 'RTVE drónos elválasztó',
        size: '1920-1080',
        positionInAllCategory: 2400
      },
      {
        img: '10',
        title: 'Map animation | Motion Design',
        imgSrc: './portfolio/motion/kerekto_terkep.jpg',
        videoSrc: './portfolio/motion/kerekto_terkep.mp4',
        meta: 'Kerektó térkép',
        size: '1920-1080',
        positionInAllCategory: 3100
      },

      {
        img: '11',
        title: 'Christmas ident | Graphic design - 2D Animation',
        imgSrc: './portfolio/motion/xmas.jpg',
        videoSrc: './portfolio/motion/xmas.mp4',
        meta: 'Branding',
        size: '1920-1080',
        positionInAllCategory: 1600
      },
      /*{
        img: '12',
        title: 'An old tv channel id | Motion design - Animation',
        imgSrc: './portfolio/motion/rtve_id_2016.jpg',
        videoSrc: './portfolio/motion/rtve_id_2016.mp4',
        meta: 'RTVE ID 2016',
        size: '1920-1080',
        positionInAllCategory: 4100
      }*/
      {
        img: '13',
        title: 'Industrial promo | Video editing - Graphic design - Animation',
        imgSrc: './portfolio/motion/labatlan_industrial.jpg',
        videoSrc: './portfolio/motion/labatlan_industrial.mp4',
        meta: 'Graphics',
        size: '1920-1080',
        positionInAllCategory: 1300
      },

      {
        img: '14',
        title: 'Tv channel promo | Motion Design - Editing',
        imgSrc: './portfolio/motion/rtve_reel2020.jpg',
        videoSrc: './portfolio/motion/rtve_reel2020.mp4',
        meta: 'Branding',
        size: '1920-1080',
        positionInAllCategory: 400
      },
      {
        img: '15',
        title: 'Sport promo | Facebook cover',
        imgSrc: './portfolio/grafika/lelato_fb_cover.jpg',
        videoSrc: '',
        meta: 'Lelátó fb cover',
        size: '1920-1080',
        positionInAllCategory: 3200
      },

      {
        img: '16',
        title: 'RTVE channel promo | Motion Design',
        imgSrc: './portfolio/motion/rtve_megyei_promo.jpg',
        videoSrc: './portfolio/motion/rtve_megyei_promo.mp4',
        meta: 'RTVE megyei promó',
        size: '1920-1080',
        positionInAllCategory: 2100
      },
      {
        img: '17',
        title: 'Promo - castle theatre booklet | graphic design - animation',
        imgSrc: './portfolio/motion/varszinhaz.jpg',
        videoSrc: './portfolio/motion/varszinhaz.mp4',
        meta: 'Várszínház',
        size: '1920-1080',
        positionInAllCategory: 2600
      },
      {
        img: '18',
        title: 'Local cinema programme promo | Motion Design',
        imgSrc: './portfolio/motion/10_s_mozi.jpg',
        videoSrc: './portfolio/motion/10_s_mozi.mp4',
        meta: '10-es mozi',
        size: '1920-1080',
        positionInAllCategory: 2700
      }
      /* {
        img: '7',
        title: 'Tv programme id | Motion Design',
        imgSrc: './portfolio/motion/musorajanlo_2016.jpg',
        videoSrc: './portfolio/motion/musorajanlo_2016.mp4',
        meta: 'RTVE műsorajánló',
        size: '1920-1080',
        positionInAllCategory: 2900
      }*/
    ]
  },
  {
    category: 'Side projects',
    items: [
      {
        img: '3',
        title: 'INHALE BY TIANSEN - CONCERT OPENER ON LED WALL | MOTION DESIGN, COMPOSITING',
        imgSrc: './portfolio/motion/inhale.jpg',
        videoSrc: './portfolio/motion/inhale.mp4',
        meta: 'inhale',
        size: '1920-1080',
        positionInAllCategory: 4400
      },
      {
        img: '01',
        title: 'POISONED BY Tiansen -  Concert visual on LED wall | Motion design, compositing',
        imgSrc: './portfolio/motion/poisoned.jpg',
        videoSrc: './portfolio/motion/poisoned.mp4',
        meta: 'Identity',
        size: '1920-1080',
        positionInAllCategory: 600
      },
      {
        img: '00',
        title: 'Style frame - Tiansen | Design concept for concert visual',
        imgSrc: './portfolio/grafika/poisoned_stf.jpg',
        videoSrc: '',
        meta: 'Grafika',
        size: '1920-1080',
        positionInAllCategory: 5500
      },

      {
        img: '6',
        title: 'Style frame - Tiansen | Design concept',
        imgIndex: './portfolio/motion/black_clouds_sf.jpg',
        imgSrc: './portfolio/motion/black_clouds_sf.jpg',
        videoSrc: '',
        meta: 'Híradó stlye frame',
        size: '1920-1080',
        positionInAllCategory: 4900
      },
      {
        img: '1',
        title: 'BLACK CLOUDS BY TIANSEN - CONCERT visual ON LED WALL | MOTION DESIGN, COMPOSITING',
        imgSrc: './portfolio/motion/black_clouds.jpg',
        videoSrc: './portfolio/motion/black_clouds.mp4',
        meta: 'black_clouds',
        size: '1920-1080',
        positionInAllCategory: 4200
      },
      {
        img: '00',
        title: 'Tiansen lOGO for concert visual | Motion design',
        imgSrc: './portfolio/motion/emeralds_sf.jpg',
        videoSrc: '',
        meta: 'Grafika',
        size: '1920-1080',
        positionInAllCategory: 5000
      },
      {
        img: '5',
        title: 'Style frame - Tiansen | Design concept',
        imgIndex: './portfolio/grafika/tiansen_stf1.jpg',
        imgSrc: './portfolio/grafika/tiansen_stf1.jpg',
        videoSrc: '',
        meta: 'Tiansen lyric video',
        size: '2560-423',
        positionInAllCategory: 1800
      },
      {
        img: '6',
        title: 'Style frame - Tiansen | Design concept',
        imgIndex: './portfolio/grafika/tiansen_stf2.jpg',
        imgSrc: './portfolio/grafika/tiansen_stf2.jpg',
        videoSrc: '',
        meta: 'Tiansen lyric video',
        size: '2560-423',
        positionInAllCategory: 1900
      },

      {
        img: '02',
        title: 'Tiansen lyric video | Motion design',
        imgSrc: './portfolio/motion/tiansen.jpg',
        videoSrc: './portfolio/motion/tiansen.mp4',
        meta: 'Tiansen',
        size: '1920-1080',
        positionInAllCategory: 2000
      },

      {
        img: '4',
        title: 'EXHALE BY TIANSEN - CONCERT visual ON LED WALL | MOTION DESIGN, COMPOSITING',
        imgSrc: './portfolio/motion/exhale.jpg',
        videoSrc: './portfolio/motion/exhale.mp4',
        meta: 'exhale',
        size: '1920-1080',
        positionInAllCategory: 4500
      },
      {
        img: '2',
        title: 'EMERALDS OF HAPPINESS BY TIANSEN - CONCERT visual ON LED WALL | MOTION DESIGN, COMPOSITING',
        imgSrc: './portfolio/motion/emeralds.jpg',
        videoSrc: './portfolio/motion/emeralds.mp4',
        meta: 'emeralds',
        size: '1920-1080',
        positionInAllCategory: 4300
      }
    ]
  },
  {
    category: 'Graphic Design',
    items: [
      {
        img: '1',
        title: 'Logo design | Graphic design - Animation',
        imgSrc: './portfolio/motion/dpgve.jpg',
        videoSrc: './portfolio/motion/dpgve.mp4',
        meta: 'DPGVE',
        size: '1920-1080',
        positionInAllCategory: 4000
      },

      {
        img: '2',
        title: 'Concept - A/5 flyer | Graphic design',
        imgIndex: './portfolio/grafika/a5_szorolap_rtve_cmyk_index.jpg',
        imgSrc: './portfolio/grafika/a5_szorolap_rtve_cmyk.jpg',
        videoSrc: '',
        meta: 'RTVE szolgáltatás',
        size: '1820-2552',
        positionInAllCategory: 3300
      },
      {
        img: '3',
        title: 'Print - A/5 flyer | Graphic design',
        imgIndex: './portfolio/grafika/a5_szorolap_megvalosult2_index.jpg',
        imgSrc: './portfolio/grafika/a5_szorolap_megvalosult2.jpg',
        videoSrc: '',
        meta: 'Graphics',
        size: '1280-576',
        positionInAllCategory: 3400
      },
      /*
      {
        img: '4',
        title: 'Job alert illustration | Graphic design',
        imgIndex: './portfolio/grafika/rtve_job_index.jpg',
        imgSrc: './portfolio/grafika/rtve_job.jpg',
        videoSrc: '',
        meta: 'Dolgozz velünk',
        size: '2560-423',
        positionInAllCategory: 3500
      },*/
      {
        img: '5',
        title: 'Facebook cover | Graphic design',
        imgIndex: './portfolio/grafika/rtve_facebook_index.jpg',
        imgSrc: './portfolio/grafika/rtve_facebook.jpg',
        videoSrc: '',
        meta: 'RTVE Facebook',
        size: '1920-1080',
        positionInAllCategory: 3600
      },
      {
        img: '6',
        title: 'Style frame - news opener | 3D motion design',
        imgIndex: './portfolio/grafika/hirado_1.jpg',
        imgSrc: './portfolio/grafika/hirado_1.jpg',
        videoSrc: '',
        meta: 'Híradó stlye frame',
        size: '1920-1080',
        positionInAllCategory: 3700
      },
      {
        img: '7',
        title: 'Style frame - news opener | 3D render',
        imgIndex: './portfolio/grafika/hirado_2.jpg',
        imgSrc: './portfolio/grafika/hirado_2.jpg',
        videoSrc: '',
        meta: 'Híradó stlye frame',
        size: '1920-1080',
        positionInAllCategory: 3800
      },
      {
        img: '8',
        title: 'Youtube header | Graphic design',
        imgIndex: './portfolio/grafika/rtve_yt_01_text_index.jpg',
        imgSrc: './portfolio/grafika/rtve_yt_01_text.jpg',
        videoSrc: '',
        meta: 'RTVE Youtube',
        size: '1920-1080',
        positionInAllCategory: 3900
      }
    ]
  }
];
