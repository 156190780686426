import React from 'react';
import Header from '../../components/header/Header';
import Slider from '../../components/slider/Slider';
import About from '../../components/about/AboutFour';
import Portfolio from '../../components/portfolio/Portfolio';
import Skills from '../../components/skills/Skills';
import Video from '../../components/video/Video';
import Brand from '../../components/Brand';
import Testimonial from '../../components/testimonial/Testimonial';
import News from '../../components/news/News';
import CallToAction from '../../components/calltoactions/CallToAction';
import Footer from '../../components/footer/Footer';

const HomeOne = () => {
  return (
    <div className='home-one'>
      <Header />
      {/* End Header Section */}
      <Slider />
      {/* End Slider Section */}
      <About />
      {/* End About Section */}
      <Portfolio />
      {/* End Portfolio Section */}
      {/*  <Skills />
       
       End Skills Section */}
      <Video />
      {/* End Video Section */}
      <div className='shane_tm_section'>
        <div className='shane_tm_partners'>
          <div className='container'>
            <div className='partners_inner'>
              <Brand />
            </div>
          </div>
        </div>
      </div>
      <Skills />
      {/* End shane_tm_partners 
      <div className='shane_tm_section'>
        <div className='shane_tm_testimonials'>
          <div className='container'>
            <div className='testimonials_inner'>
              <div className='left'>
                <div className='shane_tm_title'>
                  {
                    // <span>Jobs</span>
                  }
                  <span>Work Exprience</span>
                  <dl>
                    <dt>
                      <h4>Motion deisgner</h4>
                    </dt>
                    <dd className='workDate'>RTVE</dd>
                    <dd className='workDate'>2015-current</dd>
                  </dl>
                  <dl>
                    <dt>
                      <h4>Director, Video Editor</h4>
                    </dt>
                    <dd className='workDate'>RTVE</dd>
                    <dd className='workDate'>2011-2015</dd>
                  </dl>
                  <dl>
                    <dt>
                      <h4>Interior Designer</h4>
                    </dt>
                    <dd className='workDate'>Freelancer</dd>
                    <dd className='workDate'>2007-2011</dd>
                  </dl>
                </div>
              </div>

              <div className='right'>
                <div className='shane_tm_title'>
                  {
                    //<span>Education</span>
                  }
                  <span>Education</span>
                  <dl>
                    <dt>
                      <h4>Budapest College Of Communication and Business (now METU)</h4>
                    </dt>
                    <dd>BSc in Communication and Media Science</dd>
                    <dd>Expert in Communication</dd>
                    <dd>Specialized in Directing</dd>
                    <dd className='workDate'>2008-2011</dd>

                    <dt>
                      <h4>OKTAV</h4>
                    </dt>
                    <dd className='workDate'>Interior Design, HNC</dd>
                    <dd>2006-2007</dd>

                    <dt>
                      <h4>Animation (C4D)</h4>
                    </dt>
                    <dd className='workDate'>2021.</dd>
                    <dd>Digital Tutors</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}

      {/* End  shane_tm_testimonials*/}
      <News />
      {/* End Blog Section */}
      <CallToAction />
      {/* End CallToAction */}
      <Footer />
      {/* End Footer Section */}
    </div>
  );
};

export default HomeOne;
