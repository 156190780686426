/* eslint-disable jsx-a11y/anchor-is-valid */
// react
import React from 'react';
// videojs styles
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// config
import { portfolioList, _CONFIG } from '../../config/portfolioList';
// plugins
import videojs from 'video.js';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
// import lgFullscreen from 'lightgallery/plugins/fullscreen';
// styles
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/css/lg-thumbnail.css';
//import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-video.css';

import './Portfolio.scss';
/*
const onPosterClick = () => {
  const videoID = document.getElementsByClassName('lg-inner')[0].id;
  var myPlayer = videojs(videoID);

  myPlayer.on('click', function () {
    let v = document.getElementsByTagName('video')[0];
    if (!v.paused) {
      v.play();
    } else {
      v.pause();
    }
  });
};
const onHasVideo = () => {
  const collection = document.getElementsByClassName('.lg-inner');
  console.log('onHasVideo :>> ', collection);
};*/
const onInit = () => {
  const collection = document.getElementsByClassName('.lg-inner');
  console.log('collection :>> ', collection);
};

const allPort = portfolioList[0].items;
if (!allPort.length)
  portfolioList.forEach((element) => {
    if (element.items.length) allPort.push(...element.items);
  });
allPort.sort((a, b) => (a.positionInAllCategory > b.positionInAllCategory ? 1 : b.positionInAllCategory > a.positionInAllCategory ? -1 : 0));
const Portfolio = () => {
  return (
    <div className='shane_tm_section' id='portfolio'>
      <div className='shane_tm_portfolio'>
        <div className='container'>
          <div className='positon-relative'>
            <div className='shane_tm_title'>
              <div className='title_flex'>
                <div className='left'>
                  <span>Portfolio</span>
                  <h3>Creative Portfolio</h3>
                </div>
              </div>
            </div>
            <div className='portfolio_filter'>
              <Tabs>
                <TabList>
                  {portfolioList.map((val, i) => {
                    return <Tab key={i}>{val.category}</Tab>;
                  })}
                </TabList>
                <div className='portfolio_list has-effect'>
                  {portfolioList.map((tabContent, i) => (
                    <TabPanel key={i}>
                      <LightGallery onInit={onInit} /*onPosterClick={onPosterClick}*/ mobileSettings={({ controls: false }, { showCloseIcon: true }, { download: false }, { pictureInPictureToggle: false })} autoplayVideoOnSlide={false} videojs={true} videojsTheme='vjs-theme-city' gotoNextSlideOnVideoEnd={false} autoplayFirstVideo={false} enableSwipe={true} speed={500} loop={false} closable={true} swipeToClose={true} closeOnTap={true} fullScreen={true} showCloseIcon={true} download={false} pictureInPictureToggle={false} plugins={[lgVideo, lgThumbnail]} class={'video-js vjs-theme-city '} elementClassNames='gallery-container '>
                        {tabContent.items.map((item, i) => {
                          const videoSettings = JSON.stringify({ source: [{ src: item.videoSrc, type: 'video/mp4' }], attributes: { preload: false, playsinline: false, controls: true, pictureInPictureToggle: false } });
                          return item.positionInAllCategory ? (
                            item.videoSrc && item.videoSrc !== '' ? (
                              <a className='gallery-item' aria-labelledby={item.title} key={'a' + i} data-lg-size={item.size} data-video={videoSettings} data-poster={item.imgSrc}>
                                <img alt={item.title} className='img-responsive' src={item.imgSrc} />
                                {_CONFIG.showNumbersInPortfolio ? item.positionInAllCategory : ''}
                              </a>
                            ) : (
                              <a className='gallery-item' aria-labelledby={item.title} key={i} href={item.imgSrc} data-download={false} data-download-false={false}>
                                <img alt={item.title} className='img-responsive' src={item.imgIndex && item.imgIndex !== '' ? item.imgIndex : item.imgSrc} />
                                {_CONFIG.showNumbersInPortfolio ? item.positionInAllCategory : ''}
                              </a>
                            )
                          ) : null;
                        })}
                      </LightGallery>
                    </TabPanel>
                  ))}
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
