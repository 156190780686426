import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
const sendEmail = (data) => {
  emailjs.send('service_70nf7lb', 'template_1iwryu8', data, 'd6TgfCQHeJie0N0SJ').then(
    (response) => {
      alert('Thanks for Your message!');
      console.log('SUCCESS!', response.status, response.text);
    },
    (err) => {
      alert('Something went wrong, please contact me on my email address!');
      console.log('FAILED...', err);
    }
  );
};
const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data, e) => {
    e.target.reset();
    e.preventDefault();
    sendEmail(data);
    //    console.log('Message submited: ' + JSON.stringify(data));
  };
  return (
    <>
      <form className='contact_form' onSubmit={handleSubmit(onSubmit)}>
        <div className='first'>
          <ul>
            <li>
              <input {...register('name', { required: true })} type='text' placeholder='Name' />
              {errors.name && errors.name.type === 'required' && <span>Name is required</span>}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register(
                  'email',
                  {
                    required: 'Email is Required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format'
                    }
                  },
                  { required: true }
                )}
                type='email'
                placeholder='Email'
              />
              {errors.email && <span role='alert'>{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <textarea {...register('subject', { required: true })} placeholder='Message'></textarea>
              {errors.subject && <span>Subject is required.</span>}
            </li>
            {/* End subject  field */}
          </ul>
        </div>

        <div className='tokyo_tm_button'>
          <button type='submit' className='white-fill-bg fill-black'>
            Send Message
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
