import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
// config
import { portfolioList, showReel } from '../../config/portfolioList';
const Video = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className='shane_tm_section'>
      <div className='shane_tm_business_video'>
        <div className='bg'>
          <div
            className='image jarallax'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + './pictures/3_reel_picture.jpg'})`
            }}
          ></div>
          <div className='overlay'></div>
        </div>
        {/* End .bg */}

        <div className='content' id='showreel'>
          <ModalVideo channel='custom' /*channel='youtube' videoId='ZOoVOfieAF8' */ autoplay={false} isOpen={isOpen} url={showReel.videoSrc} onClose={() => setOpen(false)} />

          <span className='rounded' onClick={() => setOpen(true)}></span>

          <h3 className='text'>This is my showreel.</h3>

          <div className='shane_tm_video_button'>
            <button className='popup-youtube' onClick={() => setOpen(true)}>
              Watch Video
            </button>
          </div>
        </div>
        {/* End .content */}
      </div>
      {/* End .shane_tm_business_video */}
    </div>
    // End .shane_tm_section
  );
};

export default Video;
