import React from 'react';
import './Skills.scss';

const Skills = () => {
  return (
    <>
      <div className='shane_tm_section'>
        <div className='shane_tm_skills'>
          <div className='container'>
            <div className='skills_inner'>
              <div className='left'>
                <div className='shane_tm_title'>
                  <h3>I have high skills in motion design and video editing.</h3>
                </div>
                <div className='text'>
                  <p>
                    I do brand identity, concert visuals, tv show openers, commercial videos, prints and ready to venture out into another field, too.
                    {
                      // I do brand identity, openers, music videos, commercial videos, prints, and social media designs, ready to venture out into another field too
                      //I am working on a professional, visually sophisticated and technologically proficient, responsive and multi-functional personal portfolio template Shane.
                    }
                  </p>
                </div>
                <div className='shane_tm_button skillButton'>
                  <a href='about_me/andrea_bartfai_cv.pdf' download target={'_blank'}>
                    Download CV
                  </a>
                </div>
              </div>
              {/* End .left */}

              <div className='right'>
                <div className='shane_tm_title'>
                  <h3>I am a creative content creator currently using:</h3>
                </div>
                <div className='text'>
                  <p>Adobe After Effects, Adobe Photoshop, Adobe Illustrator, Adobe Premiere Pro, Edius, Cinema 4D</p>
                </div>
              </div>
            </div>
          </div>
          {/* End .conainer */}
        </div>
      </div>
    </>
  );
};

export default Skills;
