import React, { useEffect } from 'react';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './custom.scss';
const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='shane_tm_all_wrap'>
      <ScrollToTop />
      <Routes />
    </div>
  );
};

export default App;
