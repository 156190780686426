import React from 'react';
import { mainImages } from '../../config/mainImages';

const AboutFour = () => {
  return (
    <>
      <div className='shane_tm_section' id='about'>
        <div className='shane_tm_about'>
          <div className='container'>
            <div className='about_inner'>
              <div className='left'>
                <div className='image'>
                  <img src='/img/placeholders/1-1.jpg' alt='placeholder' />
                  <div
                    className='main'
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL + mainImages.aboutMe})`
                    }}
                  ></div>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className='right'>
                <div className='shane_tm_title'>
                  <span>About Me</span>
                  <h3>Motion Graphics Designer based in Hungary.</h3>
                </div>
                <div className='text'>
                  <p>
                    I’m an in-house Motion Graphics Designer and Video Editor. <br />
                    I like creating visually engaging content on almost any platform. <br />
                    I’m very passionate and dedicated to my work. With over ten years of experience in this beautiful field, I captured the skills and knowledge necessary to help grow your business with great design.
                  </p>
                </div>
                <p className='shane_tm_text'>
                  If you've got only 2 minutes, here's <a href='#showreel'>my showreel</a>, and if you are happy to browse a little bit more, <a href='#portfolio'>click on my portfolio</a>.
                </p>
                {/*<div className='shane_tm_button'>
                  
                    <a href='about_me/resume2.pdf' target={'_blank'}>
                      Resume
                    </a>
                  
                </div>
                */}
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default AboutFour;
